import React from 'react';
import { WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './NeedToKnow.scss';

export interface INeedToKnowProps {
    heading: string;
    text: string;
    needToKnow: {
        ftNtkHeading: string;
        ftNtkText: string;
        ftNtkImage: {
          altText: string;
          sourceUrl: string;
        }
      }[];
}

export const NeedToKnow: React.FC <INeedToKnowProps> = (props) => {
    return (
        <div className="NeedToKnow">
            <WidthContainer>
            <FadeIn>
            <div className="NeedToKnow__HeadingContainer">
                <h3 dangerouslySetInnerHTML={{ __html: props.heading }} />
                <div dangerouslySetInnerHTML={{ __html: props.text }} /> 
                {/* <img src="/images/sticky-bubbles.svg" alt="Decorative bubbles" /> */}
            </div>
            <div className="NeedToKnow__Grid">
                {props.needToKnow.map((needToKnow, index) => {
                    return (
                        
                        <div className="NeedToKnow__GridItem" key={index}>
                            <img src={needToKnow.ftNtkImage.sourceUrl} alt={needToKnow.ftNtkImage.altText} />
                            <h4 dangerouslySetInnerHTML={{ __html: needToKnow.ftNtkHeading }} />
                            <div dangerouslySetInnerHTML={{ __html: needToKnow.ftNtkText }} />
                        </div>
                    );
                })}
            </div>
            </FadeIn>
            </WidthContainer>
            <img className="NeedToKnow__SideFish" src="/images/side-fish-small.svg" alt="Decorative fish image" />
        </div>
    )
}
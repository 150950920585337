import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './Menu.scss';

export interface IMenuProps {
    // heading: string;
    menu: {
        ftMenuDescription: string;
        ftMenuFlavor: string;
        ftMenuLocation: string;
        ftMenuTexture: string;
        ftMenuTitle: string;
        ftMenuBadge: {
            altText: string;
            sourceUrl: string;
        }
        ftMenuImage: {
            altText: string;
            sourceUrl: string;
        }
    }[];
}

export const Menu: React.FC <IMenuProps> = (props) => {
    return (
        <div className="Menu">
            {/* <h3 dangerouslySetInnerHTML={{ __html: props.heading }} /> */}
            <div className="Menu__Menu">
                {props.menu.map((item, index) => {
                    return (
                        <FadeIn>
                        <div className="Menu__MenuItem" key={index}>
                            <img className="Menu__MenuItemImage" src={item.ftMenuImage.sourceUrl} alt={item.ftMenuImage.altText} />
                            <h4>{item.ftMenuTitle}</h4>
                            <p><span>Location:</span> {item.ftMenuLocation}</p>
                            <img className="Menu__MenuItemBadge" src={item.ftMenuBadge.sourceUrl} alt={item.ftMenuBadge.altText} />
                            <p dangerouslySetInnerHTML={{ __html: item.ftMenuDescription}} />
                            <p><span>Texture:</span> {item.ftMenuTexture}</p>
                            <p><span>Flavor:</span> {item.ftMenuFlavor}</p>
                        </div>
                        </FadeIn>
                    );
                })}
            </div>
        </div>
    )
}
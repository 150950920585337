import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './KeyArt.scss';

export const KeyArt: React.FC = () => {
    function castParallax() {
        let opThresh = 350;
        let opFactor = 750;

        window.addEventListener('scroll', function (event) {
            let top = this.scrollY;
            let layers = document.getElementsByClassName('KeyArt__LayerContainer');
            let layer, speed, yPos;
            for (let i = 0; i < layers.length; i++) {
                layer = layers[i];
                speed = layer.getAttribute('data-speed');
                yPos = -((top * speed) / 100);
                layer.setAttribute('style', 'top: ' + yPos + 'px');
            }
        });
    }

    useEffect(() => {
        castParallax();
    });

    return (
        <div className="KeyArt" id="home">
            <div className="KeyArt__LayerContainer" id="layer1" data-speed={2}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer2" data-speed={10}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer3" data-speed={20}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer4" data-speed={42}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer5" data-speed={42}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer6" data-speed={80}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainer" id="layer6-5" data-speed={80}>
                <div className="KeyArt__Layer" />
            </div>
            <div className="KeyArt__LayerContainerFixed" id="layer7"></div>
        </div>
    );
};

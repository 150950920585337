import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './LongTerm.scss';

interface ILongTermProps {
    leftHeading: string;
    rightHeading: string;
    rightText: string;
}

export const LongTerm: React.FC<ILongTermProps> = (props) => {
    return (
        <div className="LongTerm">
            <div className="LongTerm__Left">
                <div className="LongTerm__Sticky">
                    <h3 dangerouslySetInnerHTML={{ __html: props.leftHeading }}></h3>
                    <img src="/images/frozen-new-fresh.svg" alt="Illustration of divers and fish" />
                </div>
            </div>
            <div className="LongTerm__Right">
                <FadeIn>
                <h3 dangerouslySetInnerHTML={{ __html: props.rightHeading }}></h3>
                <p dangerouslySetInnerHTML={{ __html: props.rightText }}></p>
                </FadeIn>
            </div>
        </div>
    )
}
import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface IFadeInProps {
    delay?: number;
}

export const FadeIn: React.FC<IFadeInProps> = (props) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    const boxVariants = {
        hidden: { opacity: 0, y: 32 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: props.delay || 0.2,
            }
        }
    }

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
      }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={boxVariants}
        >
            {props.children}
        </motion.div>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

interface IHeaderProps {
    isHome?: boolean;
    isNotFound?: boolean;
}

function useWindowEvent<K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
    useEffect(() => {
        window.addEventListener(type, listener, options);
        return () => window.removeEventListener(type, listener, options);
    }, []);
}

const eventListerOptions = {
    passive: true,
};

const browser = typeof window !== 'undefined';

export function useViewportSize() {
    const [windowSize, setWindowSize] = useState({
        width: browser ? window.innerWidth : 0,
        height: browser ? window.innerHeight : 0,
    });

    const setSize = useCallback(() => {
        setWindowSize({
            width: window.innerWidth || 0,
            height: window.innerHeight || 0,
        });
    }, []);

    useWindowEvent('resize', setSize, eventListerOptions);
    useWindowEvent('orientationchange', setSize, eventListerOptions);

    return windowSize;
}

export const Header: React.FC<IHeaderProps> = ({ isHome, isNotFound }) => {
    const viewportSize = useViewportSize();

    if (viewportSize.width >= 900) return <DesktopHeader isHome={isHome} isNotFound={isNotFound} />;
    return <MobileHeader isHome={isHome} isNotFound={isNotFound} />;
};

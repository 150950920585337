import * as React from 'react';
import { Header } from '../../';
import Helmet from 'react-helmet';
import './Layout.scss';

interface ILayoutProps {
    isHome?: boolean;
    isNotFound?: boolean;

}

export const Layout: React.FC<ILayoutProps> = ({ children, isHome, isNotFound }) => {
    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap" rel="stylesheet"></link>
            </Helmet>
            <Header isHome={isHome} isNotFound={isNotFound} />
            <main>{children}</main>
            {/* <Footer /> */}
        </div>
    );
};

import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './Statistics.scss';

interface IStatisticsProps {
    heading: string;
    stats: {
        text: string;
        image: {
            altText: string;
            sourceUrl: string;
        }
    }[];
    boldText: string;
    text: string;
    consumptionText: string;
}

export const Statistics: React.FC<IStatisticsProps> = (props) => {
    return (
        <div className="Statistics">
            <FadeIn>
            <div className="Statistics__HeadingContainer">
                <h3 dangerouslySetInnerHTML={{ __html: props.heading }} />
                <img className="bubbles" src="/images/sticky-bubbles.svg" alt="decorative bubbles" />
            </div>
            <div className="Statistics__Grid">
                {
                    props.stats.map((stat, index) => {
                        return (
                            <div key={index} className="Statistics__GridItem">
                                <img src={stat.image.sourceUrl} alt={stat.image.altText} />
                                <p>{stat.text}</p>
                            </div>
                        )
                    })
                }
            </div>  
            <p className="Statistics__BoldText">{props.boldText}</p>
            <p className="Statistics__Text">{props.text}</p>
            <p className="Statistics__ConsumptionText">{props.consumptionText}</p>
            </FadeIn>
        </div>
    )
}
import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './WaveToDinnerTable.scss';

interface IWaveToTableProps {
    heading: string;
    text: string;
}

export const WaveToDinnerTable: React.FC<IWaveToTableProps> = (props) => {
    return (
        <div className="WaveToDinnerTable">
            <img className="bubbles-left" src="/images/cta-bubbles-right.svg" alt="decorative bubbles" />
            <FadeIn>
            <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
            <div className="WaveToDinnerTable__Text" dangerouslySetInnerHTML={{ __html: props.text }}></div>
            </FadeIn>
            <img className="bubbles-right" src="/images/cta-bubbles-left.svg" alt="decorative bubbles" />
        </div>
    )
}
import React from 'react';
import { WidthContainer } from '../..';
import './Aquaculture.scss';
import { FadeIn } from '../../Animation/FadeIn';

interface IAquacultureProps {
    heading: string;
    subheading: string;
}

export const Aquaculture: React.FC<IAquacultureProps> = (props) => {
    return (
        <div className="Aquaculture">
            <WidthContainer>
                <div className="Aquaculture__Inner">
                    <img className="bubbles-left" src="/images/cta-bubbles-right.svg" alt="decorative bubbles" />
                    <FadeIn>
                    <h3>{props.heading}</h3>
                    <h5>{props.subheading}</h5>
                    </FadeIn>
                    <img className="bubbles-right" src="/images/cta-bubbles-left.svg" alt="decorative bubbles" />
                </div>
            </WidthContainer>
            <img className="side-fish" src="/images/fish-sides.svg" alt="Decorative fish" />
        </div>
    )
}
import React from 'react';
import { WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './Hero.scss';

export interface IHeroProps {
    heading: string;
    // subheading: string;
    text: string;
}

export const Hero: React.FC <IHeroProps> = (props) => {
    return (
        <div className="Hero">
                    <img className="Hero__FishSideLeft" src="/images/jellies.svg" alt="Decorative jellyfish illustation" />
            <WidthContainer>

                <div className="Hero__Inner">
            <img className="bubbles-left" src="/images/cta-bubbles-right.svg" alt="decorative bubbles" />
            <FadeIn>

            <h3 dangerouslySetInnerHTML={{ __html: props.heading }} />
            {/* <h4 dangerouslySetInnerHTML={{ __html: props.subheading }} /> */}
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
            <img className="Hero__Turtle" src='/images/turtleandfish.svg' alt="Decorative turtle and fish illustration" />
            </FadeIn>

            <img className="bubbles-right" src="/images/cta-bubbles-left.svg" alt="decorative bubbles" />
            </div>
            </WidthContainer>
            <img className="Hero__FishSideRight" src="/images/fish-sides.svg" alt="Decorative fish illustation" />

        </div>
    )
}
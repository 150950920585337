import React, { useEffect, useState } from 'react';
import { Button } from '../../';
import { FadeIn } from '../../Animation/FadeIn';
import './Quiz.scss';

interface IQuizProps {
    questions: {
        question: string;
        answer: boolean;
    }[];
    resultsButton: {
        target: string;
        title: string;
        url: string;
    }
    heading: string;
    strapline: string;
}

export const Quiz: React.FC<IQuizProps> = props => {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [submitIsDisabled, setSubmitIsDisabled] = useState<boolean>(true);
    const [answers, setAnswers] = useState<boolean[]>([]);

    console.log('url', props.resultsButton);
    

    const setAnswer = (newValue: boolean) => {
        const newAnswers = [...answers];
        newAnswers[currentStepIndex] = newValue;
        setAnswers(newAnswers);
        setSubmitIsDisabled(false);
    };

    const handleSubmit = () => {
        setSubmitIsDisabled(true);
        setCurrentStepIndex(currentStepIndex + 1);
    };

    const renderResults = () => {
        let correctAnswers = 0;

        props.questions.forEach((step, stepIdx) => {
            let answer = step.answer;
            if (answer === null) answer = false;
            if (answer === answers[stepIdx]) correctAnswers++;
        });

        return (
            <div className="Quiz__ResultsContainer">
                <img className="Quiz__ResultsHeader" src="/images/results-are-in.svg" alt="The results are in" />
                {correctAnswers <= 1 && <p className="Quiz__ResultsDynText">You’re a fish out of water!</p>}
                {correctAnswers === 2 && <p className="Quiz__ResultsDynText">Just swimming along</p>}
                {correctAnswers === 3 && <p className="Quiz__ResultsDynText">You’re a Fresh Catch!</p>}
                <p className="Quiz__ResultsScore">
                    <img src="/images/stars-left.svg" />
                    <span>{correctAnswers}</span>/{props.questions.length}
                    <img src="/images/stars-right.svg" />
                </p>
                <p className="Quiz__ResultsLearnMore">Learn more about how you can create a better ocean</p>
                <Button as="a" href={props.resultsButton.url} target={props.resultsButton.target}>
                    {props.resultsButton.title}
                </Button>
            </div>
        );
    };

    return (
        <div className="Quiz" id="quiz">
            <FadeIn>
                <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                <h4>{props.strapline}</h4>
            </FadeIn>
            <FadeIn>

            <div className="Quiz__Container">
                {currentStepIndex >= props.questions.length ? (
                    <>{renderResults()}</>
                ) : (
                    <div className="Quiz__Step">
                        <p className="Quiz__StepQuestion">{props.questions[currentStepIndex].question}</p>
                        <div className="Quiz__StepButtons">
                            <button className="Quiz__StepButton" data-selected={answers[currentStepIndex] === true ? true : false} onClick={() => setAnswer(true)}>
                                true
                                <span></span>
                            </button>
                            <button className="Quiz__StepButton" data-selected={answers[currentStepIndex] === false ? true : false} onClick={() => setAnswer(false)}>
                                false
                                <span></span>
                            </button>
                        </div>
                        <Button minWidth='0px' as="button" className="Quiz__StepSubmit" disabled={submitIsDisabled} onClick={() => handleSubmit()}>
                            Next question
                        </Button>
                        <div className="Quiz__Progress">
                            <p>
                                {currentStepIndex + 1} of {props.questions.length}
                            </p>
                            <progress className="Quiz__ProgressBar" value={currentStepIndex + 1} max={props.questions.length} />
                        </div>
                    </div>
                )}
            </div>
            </FadeIn>
        </div>
    );
};

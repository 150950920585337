import { Link } from 'gatsby';
import React from 'react';
import './Button.scss';

export interface IButtonProps {
    as: 'a' | 'button' | 'link';
    onClick?: () => void;
    href?: string;
    className?: string;
    disabled?: boolean;
    target?: string;
    minWidth?: string;
}

export const Button: React.FC<IButtonProps> = (props) => {
    const minWidth = props.minWidth ? props.minWidth : '300px';

    let className = 'Button ';
    if (props.className) className = className + props.className;

    if (props.as === 'button') {
        return (
            <button  style={{ minWidth }} disabled={props.disabled ? true : false} onClick={() => props.onClick()} className={className}>{props.children}</button>
        )
    } else if (props.as === 'link') {
        return (
            <Link style={{ minWidth }} to={props.href} target={props.target} className={className}>{props.children}</Link>
        )
    } else {
        return (
            <a  style={{ minWidth }} href={props.href} target={props.target} className={className}>{props.children}</a>
        )
    }
}
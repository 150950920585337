import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './OurCommitment.scss';

export interface IOurCommitmentProps {
    text: string;
}

export const OurCommitment: React.FC <IOurCommitmentProps> = (props) => {
    return (
        <div className="OurCommitment">
            <div className="OurCommitment__LeftLane">
                <div className="OurCommitment__StickyContainer">
                    <p>
                        <img className="bubbles" src="/images/sticky-bubbles.svg" alt="Decorative bubbles" />
                    </p>
                    <img src="/images/chef.svg" alt="Chef Ilustration" />
                </div>
            </div>
            <div className="OurCommitment__RightLane">
            <FadeIn>

                <h3 dangerouslySetInnerHTML={{ __html: props.text }} />
                </FadeIn>
          

            </div>
        </div>
    )
}
import React from 'react';
import { FadeIn } from '../../Animation/FadeIn';
import './Quote.scss';

interface IQuoteProps {
    quote: string;
    author: string;
    subtext: string;
}

export const Quote: React.FC<IQuoteProps> = (props) => {
    return (
        <div className="Quote">
            <FadeIn>
            <div className="Quote__Grid">
                <div className="Quote__Left">
                    <img src="/images/scuba.svg" alt="Decorative scuba illustration" />
                </div>
                <div className="Quote__Right">
                    <p className="Quote__Quote" dangerouslySetInnerHTML={{ __html: props.quote }} />
                    <p className="Quote__Author">{props.author}</p>
                </div>
            </div>
            </FadeIn>
            <FadeIn>
            <p className="Quote__Subtext" dangerouslySetInnerHTML={{ __html: props.subtext }} />
            </FadeIn>
        </div>
    )
}
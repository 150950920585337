import { string } from 'prop-types';
import React from 'react';
import { Button } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './WhyAquaculture.scss';

interface IWhyAquacultureProps {
    blockOneHeading: string;
    blockOneText: string;
    blockThreeHeading: string;
    blockThreeText: string;
    blockTwoHeading: string;
    blockTwoText: string;
    heading: string;
}

export const WhyAquaculture: React.FC<IWhyAquacultureProps> = (props) => {
    return (
        <div className="WhyAquaculture" id="didYouKnow">
            <div className="WhyAquaculture__LeftLane">
                <div className="WhyAquaculture__StickyContainer">
                    <p>
                        <span dangerouslySetInnerHTML={{ __html: props.heading }}></span>
                        <img className="bubbles" src="/images/sticky-bubbles.svg" alt="Decorative bubbles" />
                    </p>
                    <img src="/images/fish-net.svg" alt="Whale Ilustration" />
                </div>
            </div>
            <div className="WhyAquaculture__RightLane">
                <FadeIn>
                <div className="WhyAquaculture__Block">
                    <h4>{props.blockOneHeading}</h4>
                    <p dangerouslySetInnerHTML={{ __html: props.blockOneText }} />
                </div>
                </FadeIn>
                <FadeIn>
                <div className="WhyAquaculture__Block">
                    <h4>{props.blockTwoHeading}</h4>
                    <p dangerouslySetInnerHTML={{ __html: props.blockTwoText }} />
                </div>
                </FadeIn>
                <FadeIn>
                <div className="WhyAquaculture__Block">
                    <h4>{props.blockThreeHeading}</h4>
                    <p dangerouslySetInnerHTML={{ __html: props.blockThreeText }} />
                </div>
                </FadeIn>
            </div>
        </div>
    )
}
import React, { useEffect } from 'react';
// import { motion } from 'framer-motion';
import './SmallKeyArt.scss';

export const SmallKeyArt: React.FC = () => {
    function castParallax() {
        let opThresh = 350;
        let opFactor = 750;

        window.addEventListener('scroll', function (event) {
            let top = this.scrollY;
            let layers = document.getElementsByClassName('SmallKeyArt__LayerContainer');
            let layer, speed, yPos;
            for (let i = 0; i < layers.length; i++) {
                layer = layers[i];
                speed = layer.getAttribute('data-speed');
                yPos = -((top * speed) / 100);
                layer.setAttribute('style', 'top: ' + yPos + 'px');
            }
        });
    }

    useEffect(() => {
        castParallax();
    });

    return (
        <div className="SmallKeyArt" id="home">
            <div className="SmallKeyArt__LayerContainer" id="layer1" data-speed={50}>
                <div className="SmallKeyArt__Layer" />
            </div>
            <div className="SmallKeyArt__LayerContainerFixed" id="layer2"></div>
        </div>
    );
};
